@use "sass:map";

.vuiCallout {
  width: 100%;
}

.vuiCallout--m {
  padding: $sizeM;

  .vuiCallout__closeButton {
    margin: -$sizeM * 0.5;
  }
}

.vuiCallout--s {
  padding: $sizeS;

  .vuiCallout__closeButton {
    margin: -$sizeS * 0.5;
  }
}

// Color
$color: (
  accent: (
    "background-color": transparentize($colorAccent, 0.9)
  ),
  primary: (
    "background-color": $colorPrimaryLightShade
  ),
  success: (
    "background-color": transparentize($colorSuccess, 0.9)
  ),
  warning: (
    "background-color": transparentize($colorWarning, 0.9)
  ),
  danger: (
    "background-color": $colorDangerLightShade
  ),
  neutral: (
    "background-color": $colorLightShade
  )
);

@each $colorName, $colorValue in $color {
  .vuiCallout--#{$colorName} {
    background-color: #{map.get($colorValue, "background-color")};
  }
}
