.vuiLink {
  color: $colorPrimary !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.vuiLink--button {
  display: inline;
}
