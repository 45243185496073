.vuiAppSideNavSections {
  margin-top: $sizeL;
}

.vuiAppSideNavContent-isHidden {
  .vuiAppSideNavSections {
    margin-top: $sizeXs;
  }
}

.vuiAppSideNavSection + .vuiAppSideNavSection {
  margin-top: $sizeL;
}

.vuiAppSideNavSection__title {
  color: $colorText;
  font-weight: $fontWeightBold;
  font-size: $fontSizeStandard;
}

.vuiAppSideNavSection__items {
  margin-top: $sizeS;

  & > .vuiAppSideNavLink {
    &:first-child {
      margin-top: -$appSideNavLinkSpacing;
    }

    &:last-child {
      margin-bottom: -$appSideNavLinkSpacing;
    }
  }
}
