.vuiScreenBlock {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $screenBlockZIndex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vuiScreenBlock__mask {
  width: 100%;
  height: 100%;
  background-color: transparentize($colorFullShade, 0.4);
}
