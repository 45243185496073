$appSideNavWidth: 240px;
$appSideNavWidthCollapsed: 60px;
$appSideNavLinkSpacing: $sizeXxs + 2px;

@mixin appSideNavItem {
  display: block;
  color: $colorText;
  font-size: $fontSizeStandard;
  padding: 0 $sizeM;
  margin-left: -$sizeM;
  padding-top: $appSideNavLinkSpacing;
  padding-bottom: $appSideNavLinkSpacing;
}

@import "./appSideNav";
@import "./appSideNavSections";
@import "./appSideNavTree";
