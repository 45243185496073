.vuiSearchSelectHeader {
  background-color: $colorLightShade;
  padding: $sizeM;
  border-bottom: 1px solid $borderColor;
  font-weight: $fontWeightBold;
  font-size: $fontSizeStandard;
  color: $colorDarkerShade;
}

.vuiSearchSelect__search {
  padding: $sizeXxs $sizeXs;
  border-bottom: 1px solid $borderColor;
}
