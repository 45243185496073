.vuiAccordionHeader {
  font-size: $fontSizeStandard;
  border-bottom: 1px solid $borderColor;
  width: 100%;
  padding: $sizeXs 0;

  &:hover {
    text-decoration: underline;
    background-color: $colorLightShade;
  }
}

.vuiAccordionHeader__title {
  text-align: left;
}
