.vuiChatTurn {
  position: relative;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 0 0 0, rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: $colorEmptyShade;
  padding: $sizeL $sizeS $sizeL $sizeL;
  margin-right: $sizeXxs;
  transition: all $transitionSpeed;

  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 1;
    left: $sizeXxs;
  }
}

.vuiChatTurn + .vuiChatTurn {
  margin-top: 1px;
}

.vuiChatQuestion {
  color: $colorAccent;
  font-weight: $fontWeightBold;
  font-size: $fontSizeStandard;
  margin-bottom: $sizeXs;
}

.vuiChatQuestion--error {
  color: $colorDanger;
}

.vuiChat__inspectButton {
  margin-top: -$sizeXxs;
}

.vuiChatAnswer {
  color: $colorFullShade;
}
