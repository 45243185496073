@import "../../../ui/styleUtils/index.scss";

.appHeader {
  position: fixed;
  width: 100vw;
  background-color: $colorLightShade;
  padding: $sizeXs $sizeM;
  z-index: 10;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.appHeaderLogo {
  margin-right: $sizeS;
}
