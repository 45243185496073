// Semantic colors
$colorAccent: #551edf !default;
$colorPrimary: rgb(38, 76, 214) !default;
$colorSuccess: #04821f !default;
$colorWarning: #965a15 !default;
$colorDanger: #c41535 !default;

// Semantic shades
$colorAccentLightShade: #eadfff !default;
$colorPrimaryLightShade: rgb(217, 226, 255) !default;
$colorSuccessLightShade: #e9f2e9 !default;
$colorWarningLightShade: #f4eee8 !default;
$colorDangerLightShade: #fae9eb !default;

// Neutral colors
$colorEmptyShade: #ffffff !default;
$colorLightShade: #f3f7fb !default;
$colorMediumShade: #cbcdde !default;
$colorDarkShade: #69707d !default;
$colorDarkerShade: #2c313a !default;
$colorFullShade: #000 !default;
