.vuiMenu {
  border: 1px solid $borderColor;
  border-radius: $sizeXs;
}

.vuiMenuItem + .vuiMenuItem {
  border-top: 1px solid $borderColor;
}

.vuiMenuItem {
  display: block;
  width: 100%;
  padding: $sizeS $sizeL;
  cursor: pointer;
  text-decoration: none;
  box-shadow: $shadowSmallStart;
  transition: all $transitionSpeed;
  text-decoration-color: $colorText;
  text-align: left;

  &:hover {
    box-shadow: $shadowSmallEnd;
    text-decoration: underline;
    text-decoration-color: $colorText;
  }
}
