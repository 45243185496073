.vuiAppHeader {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100vw;
  height: $appHeaderHeight;
  background-color: $colorLightShade;
  padding: $sizeXs $sizeM;
  z-index: $appHeaderZIndex;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.vuiAppHeader__inner {
  flex-grow: 1;
}
