@import "../../../ui/styleUtils/index.scss";
@import "../../../variables.scss";

.searchResultSiteCategory {
  overflow-wrap: anywhere;
}

@media screen and (max-width: $breakpointTitleIsWrapped) {
  .searchResultFilterGroup {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}
