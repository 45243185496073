.vuiBaseButtonIconContainer {
  // Ensures custom icons and vertically centered.
  line-height: 0;
}

.vuiBaseButtonLinkWrapper {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.vuiBaseButtonLinkWrapper--fullWidth {
  width: 100%;
}

.vuiBaseButton {
  white-space: nowrap;
  border-radius: $sizeXxs;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  box-shadow: $shadowSmallStart;
  transition: all $transitionSpeed;
  line-height: 1;
  cursor: pointer;
}

.vuiBaseButton--alignLeft {
  justify-content: flex-start;
}

.vuiBaseButton--alignCenter {
  justify-content: center;
}

.vuiBaseButton--alignRight {
  justify-content: flex-end;
}

.vuiBaseButton-isInert,
.vuiBaseButton-isDisabled {
  cursor: default;
  pointer-events: none;
}

.vuiBaseButton-isDisabled {
  opacity: 0.5;
}

.vuiBaseButton--left {
  .vuiBaseButtonIconContainer {
    margin-right: $sizeXs;
  }
}

.vuiBaseButton--right {
  flex-direction: row-reverse;

  .vuiBaseButtonIconContainer {
    margin-left: $sizeXs;
    margin-right: 0;
  }
}

.vuiBaseButton--fullWidth {
  width: 100%;
}

// Size
.vuiBaseButton--xs {
  font-size: $fontSizeStandard;
  padding: $sizeXxs $sizeXs;
  height: 24px;
}

.vuiBaseButton--s {
  font-size: $fontSizeStandard;
  padding: $sizeXs * 0.75 $sizeXs;
  height: 28px;
}

.vuiBaseButton--m {
  font-size: $fontSizeMedium;
  padding: $sizeXs $size;
  height: 34px;
}

.vuiBaseButton--l {
  font-size: $fontSizeMedium;
  padding: $sizeS $sizeL;
  height: 47px;
}
