$color: (
  accent: $colorAccent,
  primary: $colorPrimary,
  success: $colorSuccess,
  warning: $colorWarning,
  danger: $colorDanger,
  subdued: $colorSubdued,
  neutral: $colorText
);

@each $colorName, $colorValue in $color {
  .vuiTextColor--#{$colorName} {
    color: $colorValue !important;
  }
}
