@mixin truncateText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
