.vuiSearchInput {
  position: relative;
  display: flex;
  align-items: center;
}

.vuiSearchInput__input {
  flex-grow: 1;
  background-color: $colorEmptyShade;
  border: 1px solid $colorMediumShade;
  border-radius: $sizeXs;
  box-shadow: $shadowSmallStart;
  transition: all $transitionSpeed;
  outline-width: 1px !important;
  outline-style: solid;
  outline-color: transparent;
  outline-offset: -1px !important;

  &:focus-visible {
    outline-color: $colorPrimary !important;
    box-shadow: $shadowSmallEnd;
  }
}

.vuiSearchInput__submitButton {
  position: absolute;
  right: $sizeS;
  line-height: 0; // TODO: Should this be applied to all buttons?
  color: $colorDarkShade;
  transition: all $transitionSpeed;

  &:hover {
    color: $colorAccent;
  }
}

.vuiSearchInput--m {
  .vuiSearchInput__input {
    padding: $sizeXs $sizeM;
    font-size: $fontSizeStandard;
  }
}

.vuiSearchInput--l {
  .vuiSearchInput__input {
    padding: $sizeS $sizeM;
    font-size: $fontSizeLarge;
  }
}
